import styled from "@emotion/styled"
import { Link } from "gatsby-plugin-intl"
import { FaAngleUp } from "react-icons/fa"

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 460px;
  width: 100%;
  align-items: center;
  position: relative;

  @media (max-width: 850px) {
    position: relative;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  flex-direction: row;
  display: flex;

  @media (max-width: 850px) {
    flex-direction: column;
  }
`

export const TopDiv = styled.div`
  display: flex;
  flex: 1;
  background: ${props => props.theme.colors.black.footer_top};
  width: 100%;
  padding: 30px 20px 20px;

  @media (max-width: 850px) {
    padding: 20px;
    flex-direction: column;
  }
`

export const BotDiv = styled.div`
  display: flex;
  flex: 2;
  background: ${props => props.theme.colors.black.footer_bot};
  width: 100%;
  padding: 20px 20px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);

  @media (max-width: 850px) {
    padding: 20px;
    flex-direction: column;
  }
`

export const FooterIn = styled.div`
  background: ${props => props.theme.colors.black.dark};
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: center;
  padding: 15px 20px;
  min-height: auto;

  > li {
    margin: 0 0.4em;
    display: inline-block;
  }

  > li > a {
    @media (max-width: 550px) {
      padding: 0px;
      line-height: 1;
      margin: 0px;
    }
  }
`

export const HeadingTitle = styled.div`
  font-size: 28px;
  line-height: 1.2;
  text-transform: uppercase;
  font-family: "BebasNeueBold";
  font-weight: bold;
  background: linear-gradient(
    61.74deg,
    #ff7e80 -4.05%,
    #ffa853 52.44%,
    #ffed7d 91.92%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (max-width: 550px) {
    font-size: 21px;
  }
`

export const Heading = styled.div`
  color: #f1f1f1;
  font-size: 14px;
  :after {
    content: ""; /* This is necessary for the pseudo element to work. */
    display: block; /* This will put the pseudo element on its own line. */
    width: 30px;
    padding-top: 8px; /* This creates some space between the element and the border. */
    border-bottom: 3px solid rgba(255, 255, 255, 0.3); /* This creates the border. Replace black with whatever color you want. */
  }
  font-family: "GothamLight", sans-serif;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 550px) {
    margin: 0px 0px 5px 0px !important;
  }
`

export const LeftRight = styled.div`
  flex-direction: column;
  flex: 1;
`

export const Input = styled.input`
  background-color: #ede9e8;
  border-radius: 0px;
  border: 0;
  padding: 0 12px;
  width: 100%;
  height: 35px;
  margin: 10px 6px 10px 0px;
  box-sizing: border-box;
  background-color: #fff;
  color: #262626;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  font-family: "GothamLight";

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #262626;
    font-family: "GothamLight";
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #262626;
    font-family: "GothamLight";
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #262626;
    font-family: "GothamLight";
  }

  @media (max-width: 550px) {
    margin: 0 6px 10px 0;
    width: 98%;
  }
`

export const Button = styled.div`
  background-color: #fff;
  height: 37px;
  margin-bottom: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 55px; */
  /* background: #262626; */
  color: #262626;
  font-size: 14px;
  border: 1px solid ${props => props.theme.colors.black.footer_bot};
  transition: transform 0.3s, border 0.3s, background 0.3s, box-shadow 0.3s,
    opacity 0.3s, color 0.3s;
  transition-property: transform, border, background, box-shadow, opacity, color;
  transition-duration: 0.3s, 0.3s, 0.3s, 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease, ease, ease, ease;
  transition-delay: 0s, 0s, 0s, 0s, 0s, 0s;
  font-weight: 900;
  padding: 0 10px;

  :hover {
    cursor: pointer;
    background: ${props => props.theme.colors.black.footer_bot};
    border: 1px solid ${props => props.theme.colors.black.base};
    color: #fff;
  }

  @media (max-width: 550px) {
    margin-top: 0px;
  }
`

export const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  max-width: 450px;
`

export const Error = styled.div`
  display: flex;
  color: #f1f1f1;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 0px 10px;
`

export const Success = styled.div`
  display: block;
  color: #f1f1f1;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 0px 10px;
`

export const Message = styled.div`
  display: block;
  color: #f1f1f1;
  font-size: 14px;
  line-height: 20px;
  margin: 0px 0px 10px;

  p {
    margin: 0px;
  }
`

export const BotListWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 40px;

  @media (max-width: 550px) {
    margin: 0px 0px 30px 0px;
  }
`

export const ListElementWrapper = styled(Link)`
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  font-family: "GothamLight", sans-serif !important;
  color: #f1f1f1;
  :hover {
    cursor: pointer;
    color: #f1f1f1;
  }
`

export const ListElement = styled.a`
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  font-family: "GothamLight", sans-serif !important;
  color: #f1f1f1;

  :hover {
    cursor: pointer;
    color: #f1f1f1;
  }
`

export const ButtonContact = styled.a`
  background-color: ${props => props.theme.colors.button.red};
  padding: 10px 15px;
  font-size: 14px;
  font-family: "GothamLight", sans-serif !important;
  color: ${props => props.theme.colors.white.base};
  display: inline-block;

  :hover {
    cursor: pointer;
    color: #f1f1f1;
  }
`

export const BackToTop = styled.a`
  border: 2px solid #777;
  color: #777;
  border-radius: 50%;
  position: fixed;
  bottom: 45%;
  right: 20px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  padding: 7px;
  z-index: 3;
  display: none;

  @media (max-width: 600px) {
    bottom: 65px;
  }

  :hover {
    background: #262626;
    border: 2px solid #262626;
    color: #fff;
  }

  :hover svg {
    color: #fff;
  }
`

export const TopArrow = styled(FaAngleUp)`
  font-size: 18px;
  color: #777;
`

export const EmailDiv = styled.div`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  @media (max-width: 550px) {
    flex-direction: column;
  }
`

export const InSideEmail = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SubscribeTextWrapper = styled.div`
  color: #fff;
  font-size: 14px;
  margin-top: 11px;

  @media (max-width: 550px) {
    margin-bottom: 10px;
  }

  p {
    margin: 0px 0px 5px;
    display: flex;
    align-items: center;
  }

  img {
    margin: 0px 10px 0px 0px;
    max-height: 17px;
  }
`

export const BottomText = styled(Link)`
  color: #fff;
  font-size: 12px;
  font-weight: bolder;
  text-decoration: underline;
  :hover {
    color: #fff;
    text-decoration: underline;
  }

  @media (max-width: 550px) {
    margin-bottom: 30px;
  }
`

export const BottomTextWrapper = styled.p`
  color: #fff;
  font-size: 12px;
  margin: 0px;

  a {
    color: #fff;
    text-decoration: underline;
  }

  a:hover {
    color: #fff;
    text-decoration: underline;
  }
`
